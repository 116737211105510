<template>
  <b-card>
    <b-card-body>
      <vue-apex-charts
          type="bar"
          height="350"
          :options="barChart.chartOptions"
          :series="barChart.series"
      />
    </b-card-body>
  </b-card>
</template>

<script>
import { $themeColors } from '../../../../themeConfig'
import VueApexCharts from 'vue-apexcharts'

export default {
  name: 'Barchart',
  props:[
      'series',
      'values',
  ],
  components:{
    VueApexCharts,
  },
  data() {
    return {
      barChart: {
        series: [
          {
            data: [700, 350, 480, 600, 210, 550, 150],
          },
        ],
        chartOptions: {
          chart: {
            toolbar: {
              show: false,
            },
          },
          colors: ['red'],
          plotOptions: {
            bar: {
              horizontal: true,
              barHeight: '30%',
              endingShape: 'rounded',
            },
          },
          grid: {
            xaxis: {
              lines: {
                show: false,
              },
            },
          },
          dataLabels: {
            enabled: false,
          },
          xaxis: {
            categories: ['MON, 11', 'THU, 14', 'FRI, 15', 'MON, 18', 'WED, 20', 'FRI, 21', 'MON, 23'],
          },
          yaxis: {
            // opposite: isRtl,
          },
        },
      },
    }
  },
  watch: {
    series: {
      immediate: true,
      handler(val){
        this.barChart.chartOptions = {...this.barChart.chartOptions,...{xaxis:{categories: val}}}
      }
    },
    values: {
      immediate: true,
      handler(val) {
        this.barChart.series = [{data: val}]
      }
    }
  }
}
</script>
