<template>
  <div class="w-100">
    <h4>{{$t('message.BranchReports')}}</h4>
<!--    <b-card-->
<!--        no-body-->
<!--        class="card-statistics"-->
<!--    >-->
<!--      <b-card-body class="statistics-body">-->
<!--        <b-row>-->
<!--          <b-col-->
<!--              v-for="item in 2"-->
<!--              :key="item"-->
<!--              xl="3"-->
<!--              sm="6"-->
<!--          >-->
<!--            <b-media no-body>-->
<!--              <b-media-aside-->
<!--                  class="mr-2"-->
<!--              >-->
<!--                <b-avatar-->
<!--                    size="48"-->
<!--                    :variant="'info'"-->
<!--                >-->
<!--                  <feather-icon-->
<!--                      size="24"-->
<!--                      :icon="'FileIcon'"-->
<!--                  />-->
<!--                </b-avatar>-->
<!--              </b-media-aside>-->
<!--              <b-media-body class="my-auto">-->
<!--                <h4 class="font-weight-bolder mb-0">-->
<!--                  Title-->
<!--                </h4>-->
<!--                <b-card-text class="font-small-3 mb-0">-->
<!--                  Sub title-->
<!--                </b-card-text>-->
<!--              </b-media-body>-->
<!--            </b-media>-->
<!--          </b-col>-->
<!--        </b-row>-->
<!--      </b-card-body>-->
<!--    </b-card>-->
    <b-card>
      <b-row>
        <b-col md="3">
          <v-select :options="branchs" label="name" v-model="filterBranch" ></v-select>
        </b-col>
        <b-col md="3">
          <input type="date" v-model="filters.start_date" class="form-control">
        </b-col>
        <b-col md="3">
          <input type="date" v-model="filters.end_date" class="form-control">
        </b-col>
        <b-col md="3">
          <b-button variant="primary" size="sm" @click="clientReports">
            <b-icon icon="search"></b-icon>
          </b-button>
        </b-col>
      </b-row>
    </b-card>
    <b-card class="mt-3">
<!--      <b-card no-body>-->
<!--        <b-card-body>-->
<!--          <vue-apex-charts-->
<!--              type="bar"-->
<!--              height="350"-->
<!--              :options="apexChatData.barChart.chartOptions"-->
<!--              :series="apexChatData.barChart.series"-->
<!--          />-->
<!--        </b-card-body>-->
<!--      </b-card>-->
      <b-row>
        <b-col md="4">
          <b-table :fields="fields" :items="branchClients"></b-table>
        </b-col>
        <b-col md="8">
          <vue-apex-charts
              v-if="branchClient.series.length"
              type="donut"
              height="450"
              :options="branchClient.chartOptions"
              :series="branchClient.series"
          />
        </b-col>
      </b-row>
      <b-row class="mt-4">
       <b-col md="8">
<!--         <ClientsByDate :labels="lineLabels" :values="lineValues" />-->
         <Barchart :series="barLabels" :values="barValues"  />
       </b-col>
        <b-col md="4">
          <b-table :fields="operatorFields" :items="operatorReports" class="mt-5"></b-table>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import { getBranches } from '@/api/branchs/branchs'
import vSelect from 'vue-select'
import VueApexCharts from 'vue-apexcharts'
import flatPickr from 'vue-flatpickr-component'
import apexChatData from './apexChartData'
import { getBranchClientReports } from '@/api/reports/branch'
import ClientsByDate from '@/views/reports/components/ClientsByDate'
import Barchart from '@/views/reports/components/Barchart'

const chartColors = {
  column: {
    series1: '#826af9',
    series2: '#d2b0ff',
    bg: '#f8d3ff',
  },
  success: {
    shade_100: '#7eefc7',
    shade_200: '#06774f',
  },
  donut: {
    series1: '#ffe700',
    series2: '#00d4bd',
    series3: '#826bf8',
    series4: '#2b9bf4',
    series5: '#FFA1A1',
  },
  area: {
    series3: '#a4f8cd',
    series2: '#60f2ca',
    series1: '#2bdac7',
  },
}

export default {
  name: 'BranchReport',
  components: {
    Barchart,
    ClientsByDate,
    vSelect,
    flatPickr,
    VueApexCharts,
  },
  data() {
    return {
      branchs:[],
      branchClients: [],
      apexChatData,
      rangePicker: ['2019-05-01', '2019-05-10'],
      fields: [
        {key: 'branch.name', label: this.$t('message.Branch')},
        {key: 'total', label: this.$t('message.Total')},
      ],
      filters: {
        branch_id: null,
        start_date: null,
        end_date: null,
      },
      filterBranch: null,
      operatorFields: [
          {key:'author',label: this.$t('message.Author')},
          {key:'total',label: this.$t('message.Total')},
      ],
      lineLabels: [],
      lineValues: [],
      barLabels: [],
      barValues: [],
      operatorReports: [],
      branchClient: {
        series: [],
        chartOptions: {
          legend: {
            show: true,
            position: 'bottom',
            fontSize: '14px',
            fontFamily: 'Montserrat',
          },
          colors: [
            chartColors.donut.series1,
            chartColors.donut.series5,
            chartColors.donut.series3,
            chartColors.donut.series2,
          ],
          plotOptions: {
            pie: {
              donut: {
                labels: {
                  show: true,
                  name: {
                    fontSize: '2rem',
                    fontFamily: 'Montserrat',
                  },
                  value: {
                    fontSize: '1rem',
                    fontFamily: 'Montserrat',
                    formatter(val) {
                      // eslint-disable-next-line radix
                      return `${val}`
                    },
                  },
                },
              },
            },
          },
          labels: [],
          responsive: [
            {
              breakpoint: 992,
              options: {
                chart: {
                  height: 380,
                },
                legend: {
                  position: 'bottom',
                },
              },
            },
            {
              breakpoint: 576,
              options: {
                chart: {
                  height: 320,
                },
                plotOptions: {
                  pie: {
                    donut: {
                      labels: {
                        show: true,
                        name: {
                          fontSize: '1.5rem',
                        },
                        value: {
                          fontSize: '1rem',
                        },
                        total: {
                          fontSize: '1.5rem',
                        },
                      },
                    },
                  },
                },
                legend: {
                  show: true,
                },
              },
            },
          ],
        },
      }
    }
  },
  created() {
    this.fetchBranchs();
    this.clientReports()
  },
  methods: {
    fetchBranchs(){
      getBranches().then(res => {
        this.branchs = res.data.data;
      })
    },
    clientReports() {
      this.filters.branch_id = this.filterBranch ? this.filterBranch.id : null;
      getBranchClientReports(this.filters).then(res => {
        const data = res.data.data.map(item => item.total);
        this.branchClients = res.data.data;
        this.branchClient.series = data;

        this.branchClient.chartOptions = {...this.branchClient.chartOptions, ...{labels: res.data.data.map(item => item.branch.name)}}
        this.barLabels = res.data.operatorLabels;
        this.barValues = res.data.operatorValues;
        this.operatorReports = res.data.operatorReports;
        // this.lineLabels = res.data.dates;
        // this.lineValues = Object.values(res.data.values);
      })
    }
  }
}
</script>

<style scoped>

</style>
